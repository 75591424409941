exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/aboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-business-contact-us-page-js": () => import("./../../../src/templates/businessContactUsPage.js" /* webpackChunkName: "component---src-templates-business-contact-us-page-js" */),
  "component---src-templates-business-home-page-js": () => import("./../../../src/templates/businessHomePage.js" /* webpackChunkName: "component---src-templates-business-home-page-js" */),
  "component---src-templates-business-sub-page-js": () => import("./../../../src/templates/businessSubPage.js" /* webpackChunkName: "component---src-templates-business-sub-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careersPage.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contactUsPage.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/customPage.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/eventPage.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-evoucher-page-js": () => import("./../../../src/templates/evoucherPage.js" /* webpackChunkName: "component---src-templates-evoucher-page-js" */),
  "component---src-templates-ewallet-page-js": () => import("./../../../src/templates/ewalletPage.js" /* webpackChunkName: "component---src-templates-ewallet-page-js" */),
  "component---src-templates-fees-page-js": () => import("./../../../src/templates/feesPage.js" /* webpackChunkName: "component---src-templates-fees-page-js" */),
  "component---src-templates-live-brands-page-js": () => import("./../../../src/templates/liveBrandsPage.js" /* webpackChunkName: "component---src-templates-live-brands-page-js" */),
  "component---src-templates-logos-page-js": () => import("./../../../src/templates/logosPage.js" /* webpackChunkName: "component---src-templates-logos-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-personal-home-page-js": () => import("./../../../src/templates/personalHomePage.js" /* webpackChunkName: "component---src-templates-personal-home-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-send-money-page-js": () => import("./../../../src/templates/sendMoneyPage.js" /* webpackChunkName: "component---src-templates-send-money-page-js" */)
}

